<script>
/**
 * Top-users component
 */
export default {
  props: ["users"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    getSafe(fn, defaultValue = "N/A") {
      try {
        return fn();
      } catch (error) {
        return defaultValue;
      }
    },
  },
};
</script>

<template>
  <div class="col-lg-6 col-12">
    <div class="card">
      <div class="card-body">
        <div class="float-end">
          <router-link to="/users/listing">View All Users</router-link>
        </div>
        <!-- <div class="float-end">
          <b-dropdown
            variant="white"
            toggle-class="p-0"
            menu-class="dropdown-menu-end"
          >
            <template v-slot:button-content>
              <span class="text-muted">
                All Members
                <i class="mdi mdi-chevron-down ms-1"></i>
              </span>
            </template>
            <b-dropdown-item href="#">Locations</b-dropdown-item>
            <b-dropdown-item href="#">Revenue</b-dropdown-item>
            <b-dropdown-item href="#">Join Date</b-dropdown-item>
          </b-dropdown>
        </div> -->
        <h4 class="card-title mb-4">Users</h4>

        <div data-simplebar style="max-height: 336px">
          <div class="table-responsive">
            <table class="table table-borderless table-centered table-nowrap">
              <tr>
                <td>No.</td>
                <td>User Name</td>
                <td>Contact Number</td>
                <td>Email</td>
              </tr>
              <tbody>
                <tr v-if="!users">
                  <td class="text-center" colspan="4">Record Not Found</td>
                </tr>
                <tr v-else v-for="(item, index) in users" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{getSafe(() => item.full_name, "N/A")   }}</td>
                  <td>{{getSafe(() => item.mobile, "N/A")   }}</td>
                  <td>{{getSafe(() => item.email, "N/A")   }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- enbd table-responsive-->
        </div>
        <!-- data-sidebar-->
      </div>
      <!-- end card-body-->
    </div>
    <!-- end card-->
  </div>
  <!-- end col -->
</template>
